import store from "@/store";
import { ref, onMounted, watch } from "@vue/composition-api"
import { useRouter } from "@core/utils";
import { api } from "@/services/api";
import dataFooterTables from "@/fake-db/footerDataTables-sm.json";
import { convertStingToNumber, getByDate, sumdatetime } from "@/services/filters";
import moment from "moment/moment";
import location_new from "@/fake-db/location_new.json";

export default function useEditOrder() {
    const statusCreate = ref(false)
    const customerList = ref([])
    const orderId = ref('')
    const dataCustomer = ref({})
    const customerIds = ref('')
    const customerSelection = ref('')
    const userData = ref({
        user_fname: '',
        user_lname: '',
    })
    const date = ref('');
    const searchText = ref("")
    const storeList = ref([])
    const storeSelection = ref({})
    const productList = ref([])
    const productSelection = ref({})
    const pFooter = ref(dataFooterTables)
    const isAddProductToCart = ref(false)
    const pHeaders = ref([
        {
            text: '(รหัส) ชื่อสินค้า',
            value: 'product_name',
            width: 200,
        },
        {
            text: 'ราคา',
            value: 'store_product_price',
            align: 'right',
            width: 120,
        },
        {
            text: 'คงเหลือ',
            value: 'store_product_balance',
            width: 100,
            align: 'right',
        },
        {
            text: 'เลือก',
            value: 'actions',
            align: 'right fixed',

        },
    ])
    const pLoading = ref(false)
    const dataProductEdit = ref({})
    const formAddProductToCart = ref(null)
    const loading = ref(false)
    const discountPercentList = ref([])
    const discountType = ref('1')
    const orderDetail = ref({})
    const moment = require('moment')
    const isEdit = ref(false)
    const isShowDate = ref(false)
    const isRemove = ref(false)
    const isConfirmCreateOrder = ref(false)
    const dataEdit = ref({})
    const headers = ref([
        {
            text: '(รหัส) ชื่อสินค้า',
            value: 'product_name',
            width: 200,
        },
        {
            text: 'ราคา',
            value: 'order_detail_tmp_price',
            align: 'end',
            width: 150
        },
        {
            text: 'จำนวน',
            value: 'order_detail_tmp_amount',
            align: 'end',
            width: 70
        },
        {
            text: 'ส่วนลด',
            value: 'order_detail_tmp_discount',
            align: 'end',
            width: 70
        },
        {
            text: 'ภาษี',
            value: 'order_detail_tmp_vat',
            align: 'end',
            width: 70
        },
        {
            text: 'ราคารวม',
            value: 'order_detail_tmp_total',
            align: 'end',
            width: 150
        },
        {
            text: 'เลือก',
            value: 'actions',
            align: 'end fixed',
            width: 95
        },
    ])
    const cartList = ref([])
    const transportList = ref([])
    const { router } = useRouter()
    const transportSelection = ref({
        transport_id: '',
    })
    const order_transportation_date = ref(moment(new Date()).format('YYYY-MM-DD'))
    const transportPrice = ref(0)
    const transportAmount = ref(0)
    const loadingCreate = ref(false)
    const formCreateOrder = ref(null)
    const vatList = ref([])
    const vatSelection = ref({})
    const locationList = ref(location_new)
    const address2 = ref('')
    const cartLoading = ref(false)
    const address = ref({
        SUB_DISTRICT_NAME: '',
        DISTRICT_NAME: '',
        PROVINCE_NAME: '',
        ZIPCODE: '',
    })

    const { route } = useRouter()
    onMounted(() => {
        userData.value = JSON.parse(localStorage.getItem('userData'))
        getDiscounts()

        getVat()
        getStoreList()
        getOrderDetail()

    })

    const getOrderDetail = async () => {
        const { data, response, customer } = await api.get({ path: `/orderEdit/${ route.value.params.id }` })
        if (response) {
            date.value = data.create
            orderId.value = data.order_id
            orderDetail.value = data
            customerIds.value = data.customer_id
            customerSelection.value = customer.customer_select
            console.log('data : ', data)
            dataCustomer.value.customer_phone = data.order_transportation_phone
            dataCustomer.value.customer_lname = data.order_transportation_name
            dataCustomer.value.customer_address2 = data.order_transportation_address2
            dataCustomer.value.customer_address = data.order_transportation_address
            console.log('dataCustomer', dataCustomer.value)
            dataCustomer.value.order_transportation_note = data.order_transportation_note
            transportAmount.value = data.order_transportation_amount
            getTransports(data.transport_id)
            address.value = {
                SUB_DISTRICT_NAME: data.order_transportation_subdistrict,
                DISTRICT_NAME: data.order_transportation_district,
                PROVINCE_NAME: data.order_transportation_province,
                ZIPCODE: data.order_transportation_zipcode,
            }
            getCartList(data.customer_id)
        }
    }

    const getStoreList = async () => {
        api.get({
            path: '/stores',
        }).then(({ data }) => {
            storeList.value = data
            storeSelection.value = data[0]
            getProductList()
        }).catch(err => {
            console.log(err)
        })
    }


    const getCartList = async () => {
        cartLoading.value = true
        const res = await api.get({ path: `/orderDetailTmpEdit/${ customerIds.value }?currency_id=${ localStorage.getItem('currencyId') }` })
        if (res.response) {
            getProductList()
            orderDetail.value = {
                orderDiscount: res.discount,
                orderTotal: res.total,
                orderAfterDiscount: res.priceAfterDiscount,
                orderAfterVat: res.orderAfterVat,
                orderBeforeDiscount: res.priceBeforeDiscount,
                vat: res.vat,
            }
            cartList.value = res.data
            cartLoading.value = false
        }

    }

    const getProductList = async () => {
        pLoading.value = true
        api.get({
            path: '/storeEdit',
            param: `${ storeSelection.value.store_id }?search=${ searchText.value }&customer_id=${ customerIds.value }&currency_id=${ localStorage.getItem('currencyId') }`
        }).then(({ data }) => {
            productList.value = data
            pLoading.value = false
        }).catch(err => {
            console.log(err)
        })
    }

    const addProduct = (product) => {
        productSelection.value = product
        console.log(productSelection.value)
        isAddProductToCart.value = true
    }

    const deleteItemInCart = (item) => {
        console.log('item delete', item)
        loading.value = true
        api.delete({
            path: '/orderDetailTmpEdit',
            param: item.order_detail_tmp_id,
        }).then(res => {
            store.commit('app/ALERT', {
                message: res.message,
                color: res.response ? 'success' : 'error',
            })
            getCartList()
            getProductList()
            loading.value = false
        }).catch(err => {
            loading.value = false
            console.log('err : ', err)
        })
    }

    const addProductToCart = async () => {
        const isFormValid = formAddProductToCart.value.validate()
        if (!isFormValid) return
        api.post({
            path: '/orderDetailTmpEdit',
            body: {
                customer_id: customerIds.value,
                store_product_id: productSelection.value.store_product_id,
                currency_id: localStorage.getItem('currencyId'),
                product_id: productSelection.value.product_id,
                order_detail_tmp_price: convertStingToNumber(productSelection.value.store_product_price),
                order_detail_tmp_amount: convertStingToNumber(productSelection.value.order_detail_tmp_amount),
                order_detail_tmp_vat: productSelection.value.order_detail_tmp_vat,
                order_detail_tmp_discount: discountType.value == 1 ? convertStingToNumber(productSelection.value.order_detail_tmp_discount) : '',
                promotion_discount_id: discountType.value == 2 ? productSelection.value.order_detail_tmp_discount.promotion_discount_id : ''
            }
        }).then(res => {
            store.commit('app/ALERT', {
                message: res.message,
                color: res.response ? 'success' : 'error',
            })
            getCartList()

            isAddProductToCart.value = false
            loading.value = false
        }).catch(err => {
            console.log('error : ', err)
            loading.value = false
        })

    }

    const getDiscounts = async () => {
        discountPercentList.value = []
        await api.get({
            path: `${ discountType.value == '1' ? '/percents' : '/promotionDiscounts' }`,
            param: `?search=&active=1&currency_id=${ localStorage.getItem('currencyId') }`
        }).then(({ data }) => {
            discountPercentList.value = data

            if (discountType.value === '1') {
                discountPercentList.value.unshift({
                    percent_id: '',
                    percent_value: 0,
                })
                productSelection.value.order_detail_tmp_discount = discountPercentList.value[0].percent_value || 0
            } else {
                productSelection.value.order_detail_tmp_discount = discountPercentList.value[0] || 0
            }
        }).catch(err => {
            console.log('error : ', err)
        })
    }

    watch(isAddProductToCart, (val) => {
        if (val) {
            productSelection.value.order_detail_tmp_amount = 1
            getDiscounts()
            getVat()
        }
    })

    const getCurrentTime = () => {
        return moment(new Date()).format('HH:mm:ss')
    }

    const getVat = () => {
        api.get({
            path: '/vats'
        }).then(res => {
            vatList.value = res.data
            vatList.value.unshift({
                vat_id: 0,
                vat_name: 'ไม่มีภาษี',
                vat_percent: 0,
                vat_value: 0,
            })
            productSelection.value.order_detail_tmp_vat = 0
            vatSelection.value = res.data[0]
        }).catch(err => {
            console.log('error ', err)
        })
    }

    const transportChange = () => {
        transportPrice.value = transportSelection.value.transport_price_default
    }

    const getTransports = (id) => {
        api.get({
            path: '/transports',
            param: `?active=1`,
        }).then(({ data }) => {
            transportList.value = data
            transportSelection.value = data.find(item => item.transport_id == id)
            console.log('transportSelection', transportSelection.value)
            transportPrice.value = +transportSelection.value.transport_price_default
        }).catch(err => {
            console.log('err : ', err)
        })
    }

    const createOrder = () => {
        const isFormValid = formCreateOrder.value.validate()
        if (!isFormValid) return

        loadingCreate.value = true

        const body = {
            customer_id: customerIds.value,
            transport_id: transportSelection.value.transport_id,
            old_order_id: orderId.value,
            currency_id: localStorage.getItem('currencyId'),
            order_create: date.value,
            order_transportation_price: transportPrice.value,
            order_transportation_amount: transportAmount.value,
            order_transportation_name: dataCustomer.value.customer_lname,
            order_transportation_address: dataCustomer.value.customer_address,
            order_transportation_address2: dataCustomer.value.customer_address2,
            order_transportation_subdistrict: address.value.SUB_DISTRICT_NAME,
            order_transportation_district: address.value.DISTRICT_NAME,
            order_transportation_province: address.value.PROVINCE_NAME,
            order_transportation_zipcode: address.value.ZIPCODE,
            order_transportation_status: '1',
            order_transportation_phone: dataCustomer.value.customer_phone,
            order_transportation_date: order_transportation_date.value,
            order_transportation_note: dataCustomer.value.order_transportation_note,
            order_vat: vatSelection.value.vat_value,
        }
        api.post({
            path: '/orderEdit',
            body: body,
        }).then(res => {
            store.commit('app/ALERT', {
                message: res.message,
                color: res.response ? 'success' : 'error',
            })
            if (res.response) {
                isConfirmCreateOrder.value = false
                store.commit('app/setCustomerCreateOrder', null)
                loadingCreate.value = false
                transportAmount.value = 0;
                transportPrice.value = 0;
                router.push({
                    name: 'order-list',
                })

            }
        }).catch(err => {
            console.log('err : ', err)
        }).finally(() => {
            loadingCreate.value = false
        })
    }

    return {
        dataCustomer,
        customerList,
        customerSelection,
        statusCreate,
        userData,
        date,
        searchText,
        storeList,
        storeSelection,
        productList,
        productSelection,
        pFooter,
        isAddProductToCart,
        dataProductEdit,
        pHeaders,
        pLoading,
        formAddProductToCart,
        orderDetail,
        loading,
        vatList,
        discountPercentList,
        discountType,
        transportList,
        transportSelection,
        order_transportation_date,
        transportPrice,
        transportAmount,
        loadingCreate,
        formCreateOrder,
        vatSelection,
        locationList,
        address2,
        address,
        headers,
        isEdit,
        isShowDate,
        isRemove,
        createOrder,
        dataEdit,
        isConfirmCreateOrder,
        cartList,
        cartLoading,
        deleteItemInCart,
        getTransports,
        transportChange,
        getVat,
        getCurrentTime,
        addProductToCart,
        getDiscounts,
        addProduct,
        getStoreList,
        getProductList,
        getOrderDetail,
        getCartList
    }
}