<template>
    <div>
        <h3 class="error--text">(แก้ไขออเดอร์)</h3>
        <v-row>
            <v-col cols="12" lg="6">
                <v-card max-height="120" height="120">
                    <v-card-title class="pb-0 pt-2 px-2">
                        <h4 class="font-weight-semibold">ลูกค้า</h4>
                    </v-card-title>
                    <v-divider class="mt-2 mb-1"></v-divider>
                    <v-card-title class="align-center justify-center d-flex pt-0 px-2">
                        <v-text-field
                                v-model="customerSelection"
                                readonly
                                filled dense label="เลือกลูกค้า"
                                hide-details>
                        </v-text-field>

                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" lg="6">
                <v-card max-height="120" height="120">
                    <v-card-title class="pb-0 pt-2 px-2">
                        <h4 class="font-weight-semibold">ข้อมูลพนักงานขาย</h4>
                    </v-card-title>
                    <v-divider class="mt-2 mb-2"></v-divider>
                    <v-card-text class="py-0 d-flex px-2">
                        <span class="font-weight-semibold ">วันที่เปิดออเดอร์ : </span>
                        <v-spacer></v-spacer>
                        <v-text-field
                                v-model.trim="date"
                                label="วันที่สร้างออเดอร์"
                                dense
                                style="max-width: 200px"
                                hide-details
                                outlined
                                readonly
                        >
                        </v-text-field>
                    </v-card-text>
                    <v-card-text class="pb-0 pt-2 d-flex px-2">
                        <span class="font-weight-semibold">พนักงาน : </span>
                        <v-spacer></v-spacer>
                        <span>
          {{ userData.user_fname }} {{ userData.user_lname }}
        </span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="5">
                <v-card>
                    <v-card-title class="pb-0 pt-2 px-2">
                        <h4 class="font-weight-semibold">รายการสินค้า</h4>
                    </v-card-title>
                    <v-divider class="mt-2 mb-1"></v-divider>
                    <v-card-text class="d-flex px-2">
                        <v-autocomplete :items="storeList" v-model="storeSelection" filled hide-details
                                        dense
                                        item-text="store_name"
                                        class="me-2"
                                        style="width: 25%"
                                        return-object
                                        no-data-text="ไม่พบข้อมูล"
                                        @change="getProductList"
                                        label="เลือกคลังสินค้า"></v-autocomplete>
                        <v-text-field v-model="searchText" label="ค้นหาสินค้า" placeholder="รหัส, ชื่อ" filled dense
                                      hide-details
                                      @input="getStoreList">

                        </v-text-field>
                    </v-card-text>
                    <v-data-table
                            :headers='pHeaders'
                            :items='productList'
                            fixed-header
                            :footer-props='pFooter'
                            :loading='pLoading'
                            mobile-breakpoint='0'
                            height='350'
                            dense
                            disable-sort
                            loading-text="กำลังโหลดข้อมูล..."
                            no-data-text="ไม่พบข้อมูล"
                    >
                        <template v-slot:[`item.product_name`]='{ item }'>
                            ({{ item.product_code }}) {{ item.product_name }}
                        </template>
                        <template v-slot:[`item.store_product_price`]='{ item }'>
                            <span>{{ item.store_product_price }} <CurrentCurrency/></span>
                        </template>
                        <template v-slot:[`item.actions`]='{ item }'>
                            <v-tooltip top>
                                <template v-slot:activator='{ on, attrs }'>
                                    <v-btn icon small @click="addProduct(item)" color="primary" v-bind='attrs'
                                           v-on='on'>
                                        <v-icon>{{ mdiCartPlus }}</v-icon>
                                    </v-btn>
                                </template>
                                <span>เพิ่มลงตะกร้า</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
            <v-col cols="12" lg="7">
                <v-card height="280" max-height="280">
                    <v-card-title class="pb-0 pt-2 px-2">
                        <h4 class="font-weight-semibold">ตะกร้าสินค้า</h4>
                        <v-spacer></v-spacer>
                        <span class="primary--text">รวม (Exclude VAT) : {{ (orderDetail.orderBeforeDiscount || 0)  |formatPrice
                            }} <CurrentCurrency/></span>
                    </v-card-title>
                    <v-divider class="mt-2"></v-divider>
                    <v-data-table
                            :headers='headers'
                            :items='cartList'
                            fixed-header
                            hide-default-footer
                            :loading='cartLoading'
                            height="240"
                            mobile-breakpoint='0'
                            dense
                            disable-sort
                            loading-text="กำลังโหลดข้อมูล..."
                            no-data-text="ไม่พบข้อมูล"
                    >
                        <template v-slot:[`item.product_name`]='{ item }'>
                            <v-tooltip
                                    color="#212121"
                                    right
                            >
                                <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon color="primary">{{ mdiInformationOutline }}</v-icon>
                </span>
                                </template>
                                <div class="">
                                    <div class="d-flex justify-space-between">
                                        <div class="font-weight-bold me-2">ราคาก่อนหักส่วนลด :</div>
                                        <div class="font-weight-bold">
                                            {{ item.product_detail.amount |formatPrice }}
                                            <CurrentCurrency/>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <span class="font-weight-bold">ส่วนลด : </span>
                                        <span class="font-weight-bold">
                  {{ item.product_detail.discount | formatPrice }} <CurrentCurrency/>
                </span>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <span class="font-weight-bold">ภาษี : </span>
                                        <span class="font-weight-bold">
                  {{ item.product_detail.vat | formatPrice }}
                <CurrentCurrency/>
                </span>
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <span class="font-weight-bold">รวม : </span>
                                        <span class="font-weight-bold">
                  {{ item.product_detail.total | formatPrice }}
                <CurrentCurrency/>
                </span>
                                    </div>
                                </div>
                            </v-tooltip>
                            <span class="ms-1">{{ item.product_name }}</span>
                        </template>
                        <template v-slot:[`item.order_detail_tmp_discount`]='{ item }'>
                            {{ item.order_detail_tmp_discount }}
                        </template>
                        <template v-slot:[`item.order_detail_tmp_vat`]='{ item }'>
                            {{ item.order_detail_tmp_vat }}
                        </template>
                        <template v-slot:[`item.order_detail_tmp_price`]='{ item }'>
                            {{ item.order_detail_tmp_price }}
                            <CurrentCurrency/>
                        </template>
                        <template v-slot:[`item.order_detail_tmp_total`]='{ item }'>
                            {{ item.order_detail_tmp_total }}
                            <CurrentCurrency/>
                        </template>
                        <template v-slot:[`item.actions`]='{ item }'>
                            <v-tooltip top>
                                <template v-slot:activator='{ on, attrs }'>
                                    <v-btn icon small color="error" v-bind='attrs'
                                           @click="deleteItemInCart(item)"
                                           v-on='on'>
                                        <v-icon>{{ mdiDeleteOutline }}</v-icon>
                                    </v-btn>
                                </template>
                                <span>ลบ</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card>
                <div class="d-flex align-center justify-center">
                    <v-card color="mt-2" width="50%" height="234.2"
                            max-height="234.2">
                        <v-card-title class="pb-0 pt-2 px-2">
                            <h4 class="font-weight-semibold">
                                ค่าขนส่ง
                            </h4>
                        </v-card-title>
                        <v-divider class="mt-2"></v-divider>
                        <v-card-text class="px-2">
                            <v-select :items="transportList" v-model="transportSelection" label="เลือกช่องทางขนส่ง"
                                      dense
                                      outlined
                                      hide-details
                                      @change="transportChange"
                                      return-object
                                      no-data-text="ไม่มีข้อมูล" item-text="transport_name" item-value="transport_id">
                            </v-select>
                            <div class="d-flex mt-3">
                                <v-text-field v-model="transportPrice" label="ราคาขนส่ง/กล่อง" dense outlined
                                              class="me-1"
                                              hide-details
                                              reverse
                                              disabled
                                              type="number"
                                              no-data-text="ไม่มีข้อมูล" item-text="transport_name"
                                              item-value="transport_id">
                                </v-text-field>
                                <v-text-field v-model="transportAmount" label="จำนวนกล่องพัสดุ" dense outlined
                                              hide-details
                                              reverse
                                              type="number"
                                              no-data-text="ไม่มีข้อมูล" item-text="transport_name"
                                              item-value="transport_id">
                                </v-text-field>
                            </div>
                            <div class="d-flex align-center justify-space-between font-weight-semibold mt-11">
                                <span>ค่าจัดส่งรวม</span>
                                <span class="primary--text">
              {{ transportPrice * transportAmount |formatPrice }} <CurrentCurrency/>
            </span>
                            </div>
                        </v-card-text>
                    </v-card>
                    <v-card color="mt-2 ms-2" width="50%"
                            height="234.2"
                            max-height="234.2">
                        <v-card-title class="pb-0 pt-2 px-2">
                            <h4 class="font-weight-semibold">
                                สรุปยอดออเดอร์
                            </h4>
                        </v-card-title>
                        <v-divider class="mt-2"></v-divider>
                        <v-card-text class="px-2 pt-0 d-flex flex-column">
                            <div class="d-flex align-center justify-space-between my-1 font-weight-semibold">
                                <span>ส่วนลด</span>
                                <span class="primary--text">{{ (orderDetail.orderDiscount || 0)|formatPrice }} <CurrentCurrency/></span>
                            </div>

                            <div class="d-flex align-center justify-space-between my-1 font-weight-semibold">
                                <span>ภาษีรวม</span>
                                <span class="primary--text">{{ (orderDetail.vat || 0)| formatPrice }}<CurrentCurrency/></span>
                            </div>
                            <div class="d-flex align-center justify-space-between my-1 font-weight-semibold">
                                <span>ราคารวม (Include VAT)</span>
                                <span class="primary--text">{{ (orderDetail.orderAfterVat || 0)|formatPrice }} <CurrentCurrency/></span>
                            </div>
                            <div class="d-flex align-center justify-space-between my-1 font-weight-semibold ">
                                <span class="error--text">ราคารวม + ค่าจัดส่ง</span>
                                <span class="primary--text">{{ ((transportPrice * transportAmount) + parseFloat(orderDetail.orderTotal)) + ((transportPrice * transportAmount) + parseFloat(orderDetail.orderTotal)) * vatSelection.vat_value / 100 |formatPrice
                                    }}<CurrentCurrency/>
                                </span>
                            </div>
                            <v-btn color="primary" block rounded class="mt-6" @click="isConfirmCreateOrder = true"
                                   :disabled="!cartList.length || transportList[0].transport_id === transportSelection.transport_id">
                                <v-icon class="me-1">{{ mdiReceipt }}</v-icon>
                                บันทึกออเดอร์
                            </v-btn>
                        </v-card-text>

                    </v-card>
                </div>
            </v-col>
        </v-row>
        <v-dialog v-model="isAddProductToCart" persistent max-width="550">
            <v-card>
                <v-card-title>
                    {{ productSelection.product_name }}
                    <v-spacer></v-spacer>
                    <span
                            class="primary--text">คลัง : {{ productSelection.store_name }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-form ref="formAddProductToCart" @submit.prevent="addProductToCart">
                    <v-row class="mx-2 my-2">
                        <v-col cols="12" class="d-flex pb-0">
                            <v-text-field
                                    v-model="productSelection.store_product_price"
                                    label="ราคา"
                                    filled
                                    dense
                                    hide-details="auto"
                                    readonly
                                    class="me-2"
                            ></v-text-field>
                            <v-text-field
                                    v-model="productSelection.order_detail_tmp_amount"
                                    label="จำนวน"
                                    filled
                                    dense
                                    hide-details="auto"
                                    type="number"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class='d-flex flex-column'>
                            <v-radio-group v-model="discountType" @change="getDiscounts" hide-details="auto" row>
                                <v-radio
                                        label="เปอร์เซ็นต์ส่วนลด"
                                        value="1"
                                ></v-radio>
                                <v-radio
                                        :label="`โค้ดส่วนลด`"
                                        value="2"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" class='d-flex'>
                            <v-select
                                    v-model="productSelection.order_detail_tmp_discount"
                                    :label="`ส่วนลด ${discountType==='1'?'(%)':''}`"
                                    no-data-text="ไม่มีข้อมูล"
                                    filled
                                    :items="discountPercentList"
                                    :item-text="discountType ==1?`percent_value`:`promotion_discount_code`"
                                    :item-value="discountType ==1?`percent_value`:`promotion_discount_id`"
                                    class="me-2"
                                    dense
                                    hide-details="auto"
                                    :return-object="discountType==2"
                                    type="number"

                            ></v-select>
                            <v-select
                                    v-model="productSelection.order_detail_tmp_vat"
                                    label="ภาษี"
                                    filled
                                    no-data-text="ไม่มีข้อมูล"
                                    :items="vatList"
                                    item-text="vat_value"
                                    item-value="vat_value"
                                    dense
                                    hide-details="auto"
                                    :prepend-inner-icon="mdiPercentOutline"
                                    type="number"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" class="d-flex py-0">
                            <span class="font-weight-semibold">ราคา</span>
                            <v-spacer></v-spacer>
                            <span class="primary--text text-decoration-underline">
                {{
                                    productSelection|sumOrderTotal3
                                }}</span>
                        </v-col>
                        <v-col cols="12" class="d-flex py-0">
                            <span class="font-weight-semibold">ส่วนลด</span>
                            <v-spacer></v-spacer>
                            <span class="primary--text text-decoration-underline" v-if="discountType==1">
                {{
                                    productSelection | sumOrderTotal4
                                }}</span>
                            <span v-else class="primary--text text-decoration-underline">
                {{ +productSelection.order_detail_tmp_discount.promotion_discount_amount || 0 | formatPrice }}
              </span>
                        </v-col>
                        <v-col cols="12" class="d-flex py-0">
                            <span class="font-weight-semibold">ภาษี</span>
                            <v-spacer></v-spacer>
                            <span class="primary--text text-decoration-underline" v-if="discountType ==1">
                  {{ productSelection | sumOrderTotalVat }}
              </span>
                            <span class="primary--text text-decoration-underline" v-else>
                {{ productSelection | sumOrderTotalVat2 }}
              </span>
                        </v-col>
                        <v-col cols="12" class="d-flex py-0">
                            <span class="font-weight-semibold">ราคารวม</span>
                            <v-spacer></v-spacer>
                            <span class="primary--text text-decoration-underline" v-if="discountType ==1">
                {{
                                    productSelection | sumOrderTotal
                                }}</span>
                            <span class="primary--text text-decoration-underline" v-else>
                {{
                                    productSelection | sumOrderTotal2
                                }}
              </span>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" :loading="loading" :disabled="loading" type="submit">บันทึก</v-btn>
                        <v-btn color="secondary" outlined @click="isAddProductToCart = false">ยกเลิก
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isConfirmCreateOrder" persistent max-width="500">
            <v-card v-if="isConfirmCreateOrder">
                <v-card-title class="px-2">
          <span class="">
            ยืนยันการสั่งซื้อ
          </span>
                    <v-spacer></v-spacer>
                    <span class="primary--text text-decoration-underline">
           รวม : {{
                            ((transportPrice * transportAmount) + parseFloat(orderDetail.orderTotal)) + ((transportPrice * transportAmount) + parseFloat(orderDetail.orderTotal)) * vatSelection.vat_value / 100 |formatPrice
                        }}
          </span>
                </v-card-title>
                <v-divider></v-divider>
                <v-form ref="formCreateOrder" @submit.prevent="createOrder">
                    <v-card-text class="px-0 py-0 ">
                        <div class="font-weight-bold px-2 text-decoration-underline d-flex">
            <span>
              ข้อมูลออเดอร์
            </span>

                        </div>
                        <div class="px-2">
                            <div class="d-flex align-center justify-space-between">
            <span class=" font-weight-semibold">
              ลูกค้า :
            </span>
                                <span>{{ customerSelection }}</span>
                            </div>
                            <div class="d-flex align-center justify-space-between">
            <span class=" font-weight-semibold">
              วันที่เปิดออเดอร์ :
            </span>
                                <span>{{ date }}</span>
                            </div>
                            <div class="d-flex align-center justify-space-between">
            <span class=" font-weight-semibold">
              พนักงานขาย :
            </span>
                                <span>   {{ userData.user_lname }}</span>
                            </div>
                        </div>
                        <v-divider class="mt-2"></v-divider>
                        <div class="font-weight-bold px-2 text-decoration-underline d-flex">
                            <span>ข้อมูลการจัดส่ง</span>
                            <v-spacer></v-spacer>
                            <span>{{ transportSelection.transport_name }}</span>
                        </div>
                        <div class="px-1 pt-2">
                            <v-row no-gutters>
                                <v-col cols='12' md='6' class="my-1 px-1">
                                    <v-text-field v-model='dataCustomer.customer_phone'
                                                  dense
                                                  hide-details='auto'
                                                  label='เบอร์โทรศัพท์'
                                                  placeholder='เบอร์โทรศัพท์'
                                                  outlined
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols='12' md='6' class="my-1 px-1">
                                    <v-text-field v-model='dataCustomer.customer_lname' dense
                                                  hide-details='auto'
                                                  label='ชื่อผู้รับสินค้า'
                                                  placeholder='ชื่อผู้รับสินค้า'
                                                  outlined
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col cols='12' class="my-1 px-1" md='6'>
                                    <v-text-field
                                            v-model='address.PROVINCE_NAME'
                                            label='จังหวัด'
                                            placeholder="จังหวัด"
                                            hide-details='auto'
                                            dense
                                            outlined
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols='12' class="my-1 px-1" md='6'>
                                    <v-text-field
                                            v-model='address.DISTRICT_NAME'
                                            label='อำเภอ'
                                            placeholder="อำเภอ"
                                            hide-details='auto'
                                            dense
                                            outlined
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols='12' class="my-1 px-1" md='6'>
                                    <v-text-field
                                            v-model='address.SUB_DISTRICT_NAME'
                                            label='ตำบล'
                                            hide-details='auto'
                                            placeholder=""
                                            dense
                                            outlined
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols='12' class="my-1 px-1" md='6'>
                                    <v-text-field
                                            v-model='dataCustomer.customer_address2'
                                            label='หมู่บ้านหรือถนน'
                                            placeholder="หมู่บ้านหรือถนน"
                                            hide-details='auto'
                                            dense
                                            outlined
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols='12' class="my-1 px-1" md='6'>
                                    <v-text-field
                                            v-model='address.ZIPCODE'
                                            label='รหัสไปรษณีย์'
                                            placeholder="รหัสไปรษณีย์"
                                            hide-details='auto'
                                            dense
                                            outlined
                                    >

                                    </v-text-field>
                                </v-col>
                                <v-col cols='12' md='6' class="my-1 px-1">
                                    <v-text-field v-model='dataCustomer.customer_address' dense
                                                  hide-details='auto'
                                                  label='ที่อยู่ - เพิ่มเติม'
                                                  placeholder='ที่อยู่ - เพิ่มเติม'
                                                  outlined
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols='12' class="my-1 px-1" md='6'>
                                    <v-dialog
                                            ref="dateStart"
                                            v-model.trim="isShowDate"
                                            :return-value.sync="order_transportation_date"
                                            width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model.trim="order_transportation_date"
                                                    label="เลือกวันที่จัดส่ง"
                                                    dense
                                                    outlined
                                                    readonly
                                                    hide-details
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :prepend-inner-icon="mdiCalendar"
                                            >
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model.trim="order_transportation_date"
                                                locale="th"
                                                scrollable
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                    text
                                                    color="primary"
                                                    @click="$refs.dateStart.save(order_transportation_date)"
                                            >
                                                ยืนยัน
                                            </v-btn>
                                            <v-btn
                                                    text
                                                    color="secondary"
                                                    @click="isShowDate = false"
                                            >
                                                ยกเลิก
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col cols='12' class="my-1 px-1">
                                    <v-textarea
                                            v-model='dataCustomer.customer_note'
                                            label="หมายเหตุ"
                                            placeholder="หมายเหตุ เพิ่มเติมเกี่ยวกับการส่งสินค้า"
                                            outlined
                                            dense
                                            hide-details='auto'
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit" :loading="loadingCreate" :disabled="loadingCreate">
                            บันทึก
                        </v-btn>
                        <v-btn color="secondary" outlined @click="isConfirmCreateOrder = false">
                            ยกเลิก
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
import useEditOrder from "@/views/manager/order/editOrder/useEditOrder";
import CurrentCurrency from "@/components/CurrentCurrency.vue";
import SuspendProductInCart from "@/views/manager/order/createOrder/SuspendProductInCart.vue";
import {
    mdiPlus,
    mdiCartPlus,
    mdiPercentOutline,
    mdiReceipt,
    mdiInformationOutline,
    mdiDeleteOutline,
    mdiCalendar
} from '@mdi/js'
import {
    sumOrderTotal3,
    sumOrderTotal4,
    formatPrice,
    sumOrderTotalVat,
    sumOrderTotalVat2,
    sumOrderTotal,
    sumOrderTotal2
} from "@/services/filters";

export default {
    components: {
        SuspendProductInCart,
        CurrentCurrency
    },
    filters: {
        sumOrderTotal3,
        sumOrderTotal4,
        formatPrice,
        sumOrderTotalVat,
        sumOrderTotalVat2,
        sumOrderTotal,
        sumOrderTotal2
    },
    setup() {
        return {
            ...useEditOrder(),
            mdiPlus,
            mdiCartPlus,
            mdiPercentOutline,
            mdiReceipt,
            mdiCalendar,
            mdiInformationOutline, mdiDeleteOutline
        }
    }
}

</script>